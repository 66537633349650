<!--大屏 服务项目占比-->
<template>
  <div>
    <div id="proportion" style="height: 350px;"></div>
  </div>
</template>

<script>
import {replacement} from "@/api/yunli";
import {account_payments} from "@/api/finance";

export default {
  data(){
    return{
      institution_code:'',
      ServicesAvailable:[],
    }
  },

  mounted() {
    if(localStorage.getItem('institution_code') == '' || localStorage.getItem('institution_code') == undefined || localStorage.getItem('institution_code') == null){
      console.log(123465123)
      this.getCode();
    }else {
      console.log(456456465)
      console.log(localStorage.getItem('institution_code'))
      //获取收入总额接口
      this.getservice_ratioyj();
    }
  },

  methods:{
    // 置换机构码
    getCode(){
      replacement().then(res => {
        console.log(res);
        //大屏标题
        this.$emit('title',res.data.company_name);
        // 机构码
        this.institution_code = res.data.institution_code;
        localStorage.setItem('institution_code',res.data.institution_code);

        this.getservice_ratioyj();
      })
    },
    //获取服务项目占比接口
    getservice_ratioyj(){
      account_payments(localStorage.getItem('institution_code')).then(res => {
        this.$emit('total_amount',res.data.total_amount);
        res.data.serversList.forEach(item => {
          item.value = item.order_client_price;
        })
        this.ServicesAvailable = res.data.serversList;
        this.ServicesAvailable.forEach(item => {
          if(item.name == '送水'){
            item.value = 10;
            item.itemStyle = {
              normal: {
                color: '#D9F7FB', //背景颜色，还需设置areaStyle
                lineStyle: {
                  color: '#D9F7FB', //边框颜色
                },
              },
            }
          }else if(item.name == '拖车'){
            item.value = item.orderCount;
            item.itemStyle = {
              normal: {
                color: '#229BEB', //背景颜色，还需设置areaStyle
                lineStyle: {
                  color: '#229BEB', //边框颜色
                },
              },
            }
          }else if(item.name == '困境'){
            // item.value = item.orderCount;
            item.value = 5;
            item.itemStyle = {
              normal: {
                color: '#F2BD42', //背景颜色，还需设置areaStyle
                lineStyle: {
                  color: '#F2BD42', //边框颜色
                },
              },
            }
          }else if(item.name == '搭电'){
            // item.value = item.orderCount;
            item.value = 8;
            item.itemStyle = {
              normal: {
                color: '#27DFD9', //背景颜色，还需设置areaStyle
                lineStyle: {
                  color: '#27FFF0', //边框颜色
                },
              },
            }
          }else if(item.name == '换胎'){
            // item.value = item.orderCount;
            item.value = 6;
            item.itemStyle = {
              normal: {
                color: '#58A55C', //背景颜色，还需设置areaStyle
                lineStyle: {
                  color: '#58A55C', //边框颜色
                },
              },
            }
          }else if(item.name == '送油'){
            item.value = item.orderCount;
            item.itemStyle = {
              normal: {
                color: '#EE752F', //背景颜色，还需设置areaStyle
                lineStyle: {
                  color: '#EE752F', //边框颜色
                },
              },
            }
          }else if(item.name == '吊车'){
            item.value = item.orderCount;
            item.itemStyle = {
              normal: {
                color: '#D9F7FB', //背景颜色，还需设置areaStyle
                lineStyle: {
                  color: '#D9F7FB', //边框颜色
                },
              },
            }
          }else if(item.name == '充气'){
            item.value = item.orderCount;
            item.itemStyle = {
              normal: {
                color: '#229BEB', //背景颜色，还需设置areaStyle
                lineStyle: {
                  color: '#229BEB', //边框颜色
                },
              },
            }
          }else if(item.name == '小修'){
            item.value = item.orderCount;
            item.itemStyle = {
              normal: {
                color: '#7046FF', //背景颜色，还需设置areaStyle
                lineStyle: {
                  color: '#7046FF', //边框颜色
                },
              },
            }
          }else if(item.name == '地库'){
            item.value = item.orderCount;
            item.itemStyle = {
              normal: {
                color: '#27DFD9', //背景颜色，还需设置areaStyle
                lineStyle: {
                  color: '#27FFF0', //边框颜色
                },
              },
            }
          }
        });

        // 调用charts
        this.getCharts();
      })
    },

    getCharts() {
      const chartBox = this.$echarts.init(document.getElementById("proportion"));
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          top: 'bottom',
          data: [
            'rose1',
            'rose2',
            'rose3',
            'rose4',
            'rose5',
            'rose6',
            'rose7',
            'rose8'
          ]
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            name: '服务项目',
            type: 'pie',
            radius: '80%',
            center: ['50%', '50%'],
            roseType: 'radius',
            itemStyle: {
              borderRadius: 5,
                // 阴影的大小
                shadowBlur: 200,
                // 阴影水平方向上的偏移
                shadowOffsetX: 0,
                // 阴影垂直方向上的偏移
                shadowOffsetY: 0,
                // 阴影颜色
                shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
            // data: [
            //   {
            //     value: 335,
            //     name: '送水' ,
            //     itemStyle: { //该数值区域样式设置
            //       normal: {
            //         color: '#D9F7FB', //背景颜色，还需设置areaStyle
            //         lineStyle: {
            //           color: '#D9F7FB', //边框颜色
            //         },
            //       },
            //     }
            //   },
            //   {
            //     value: 310,
            //     name: '拖车',
            //     itemStyle: { //该数值区域样式设置
            //       normal: {
            //         color: '#229BEB', //背景颜色，还需设置areaStyle
            //             lineStyle: {
            //           color: '#229BEB', //边框颜色
            //         },
            //       },
            //     }
            //   },
            //   {
            //     value: 274,
            //     name: '困境' ,
            //     itemStyle: { //该数值区域样式设置
            //       normal: {
            //         color: '#7046FF', //背景颜色，还需设置areaStyle
            //         lineStyle: {
            //           color: '#7046FF', //边框颜色
            //         },
            //       },
            //     }
            //   },
            //   {
            //     value: 235,
            //     name: '搭电',
            //     itemStyle: { //该数值区域样式设置
            //       normal: {
            //         color: '#27DFD9', //背景颜色，还需设置areaStyle
            //         lineStyle: {
            //           color: '#27FFF0', //边框颜色
            //         },
            //       },
            //     }
            //   },
            //   {
            //     value: 400,
            //     name: '换胎' ,
            //     itemStyle: { //该数值区域样式设置
            //       normal: {
            //         color: '#FBAA1E', //背景颜色，还需设置areaStyle
            //         lineStyle: {
            //           color: '#FBAA1E', //边框颜色
            //         },
            //       },
            //     }
            //   }
            // ].sort(function (a, b) {
            //   return a.value - b.value;
            // }),
            data: this.ServicesAvailable.sort(function (a, b) {
              return a.value - b.value;
            }),

            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
              return Math.random() * 200;
            }
          },

          // 圆点背景
          {
            type: 'pie',
            name: '饼状背景',
            z: 2,
            radius: ['0%', '10%'],
            center: ['50%', '50%'],
            startAngle: 110,
            hoverAnimation: false,
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 1, [{
                  offset: 0,
                  color: '#f3f7ff'
                }, {
                  offset: 1,
                  color: '#f3f7ff'
                }])
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false
            },
            data: [50]
          },
          // 圆点外围渐变光圈
          {
            type: 'pie',
            name: '饼状背景',
            z: 2,
            radius: ['0%', '25%'],
            center: ['50%', '50%'],
            startAngle: 110,
            hoverAnimation: false,
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.RadialGradient(.5, .5, 0.5, [{
                  offset: 0,
                  color: 'rgba(233, 239, 255, 0.1)'
                },
                  {
                    offset: 0.5,
                    color: 'rgba(233, 239, 255, 0.1)'
                  }, {
                    offset: 0.9,
                    color: 'rgba(233, 239, 255, 0.42)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(233, 239, 255, 0.6)'
                  }
                ], false),
              }
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false
            },
            data: [50]
          },

          // 饼状图最外层环形光晕
          {
            type: 'pie',
            name: '饼状背景',
            z: 0,
            radius: ['0%', '90%'],
            center: ['50%', '50%'],
            startAngle: 110,
            hoverAnimation: false,
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.RadialGradient(.5, .5, 0.5, [{
                  offset: 0,
                  color: 'rgba(72, 108, 199, 0.3)'
                },
                  {
                    offset: 0.5,
                    color: 'rgba(72, 108, 199, 0.3)'
                  }, {
                    offset: 0.9,
                    color: 'rgba(72, 108, 199, 0.3)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(30, 37, 53, 0.3)'
                  }
                ], false),
              }
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false
            },
            data: [50]
          }

        ]
      };
      chartBox.setOption(option);
      // 根据页面大小自动响应图表大小
      window.addEventListener("resize", function () {
        chartBox.resize();
      });


    },

  },

}
</script>

<style lang="scss" scoped>

</style>
